(function () {
    const NUMERIC_REGEXP = /[-]{0,1}[\d]*[\.]{0,1}[\d]+/g;
    // '2.2px 3.1px 4px -7.6px obj.key'.match(NUMERIC_REGEXP)

    // simple Polyfill includes: https://www.sharmaprakash.com.np/javascript/ie-alternative-to-inludes/
    function includes(container, value) {
        var returnValue = false;
        var pos = container.indexOf(value);
        if (pos >= 0) {
            returnValue = true;
        }
        return returnValue;
    }

    // Polyfill parseInt: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/parseInt
    if (Number.parseInt === undefined) Number.parseInt = window.parseInt;

    function normalizePic(e) {
        e.preventDefault();

        const closeBtn = e.target;
        // console.log(document.querySelectorAll('.js-gallery__item'));
        // console.log(document.querySelectorAll('.js-image'));
        // console.log(document.querySelectorAll('.js-gallery__item .js-image'));

        // const openedImg = e.target.parentElement.parentElement.parentElement.querySelector('.js-image');
        const openedImg = document.querySelector(".js-gallery__item .js-image");
        // console.log(openedImg, ' vs ', openedImg2);
        // console.log(e.target.closest('.js-image'));
        const posTopBody = document.querySelector("body").style.top;

        // console.log(posTopBody.match(NUMERIC_REGEXP)[0]);
        // console.log(closeBtn);
        // console.log(document.querySelector('.js-gallery__item .js-image'));
        document.querySelector(".js-gallery").classList.remove("is-visible");
        document.querySelector("body").classList.remove("u-overflow-hidden");
        document.querySelector("body").style.top = "auto";

        // window.setTimeout(function() {
        // console.log(posTopBody.match(NUMERIC_REGEXP)[0]);
        document.querySelector("html").scrollTop =
            posTopBody.match(NUMERIC_REGEXP)[0] * -1;
        // console.log(document.querySelector('html').scrollTop);
        // }, 1000);

        document
            .querySelector(`.js-zoom > .js-empty-pic`)
            .appendChild(document.querySelector(".js-gallery__item .js-image"));

        // console.log(e.target);
        document
            .querySelector(".js-empty-pic .js-image")
            .classList.remove("c-gallery__picture");
        document
            .querySelector(".js-empty-pic")
            .classList.remove("js-empty-pic");
        // console.log();

        const bigPictures = document.querySelectorAll(".js-image");
        const thumbnails = document.querySelectorAll(
            ".js-thumbnail .js-img-bg"
        );
        getPrevAndNext(openedImg);

        // const closeButtons = document.querySelectorAll('.js-close');

        // bigPictures.forEach(picture => picture.addEventListener('click', zoom));
        // closeButtons.forEach(picture => picture.addEventListener('click', close));

        // console.log(document.querySelector('.js-gallery__item .js-image').dataset.title);
        // console.log(document.querySelector(`.js-zoom img[data-title="${document.querySelector('.js-gallery__item .js-image').dataset.title}"]`));
    }

    function loadNextImg(e) {
        // e.stopPropagation();
        e.preventDefault();

        // const imageHolder =  e.target.parentElement.classList.contains('js-next') ? e.target.parentElement.parentElement.nextElementSibling : e.target.parentElement.nextElementSibling;
        // const nextImage = e.target.parentElement.classList.contains('js-next') ? e.target.parentElement.parentElement.nextElementSibling.dataset.next : e.target.parentElement.nextElementSibling.dataset.next;
        const imageHolder =
            document.querySelector(".js-next").parentElement.nextElementSibling;
        const nextImage =
            document.querySelector(".js-next").parentElement.nextElementSibling
                .dataset.next;

        imageHolder.src = nextImage;
        getPrevAndNext(imageHolder);
    }

    function loadPrevImg(e) {
        e.preventDefault();
        // e.stopPropagation();
        const imageHolder =
            document.querySelector(".js-prev").parentElement.nextElementSibling;
        // const imageHolder2 = e.target.parentElement.classList.contains('js-prev') ? e.target.parentElement.parentElement.nextElementSibling : e.target.parentElement.nextElementSibling;

        // console.log(imageHolder);
        // console.log(imageHolder2);

        const prevImage =
            document.querySelector(".js-prev").parentElement.nextElementSibling
                .dataset.prev;
        // const prevImage2 = e.target.parentElement.classList.contains('js-prev') ? e.target.parentElement.parentElement.nextElementSibling.dataset.prev : e.target.parentElement.nextElementSibling.dataset.prev;

        // console.log(prevImage);
        // console.log(prevImage2);

        imageHolder.src = prevImage;

        getPrevAndNext(imageHolder);
    }

    function checkKey(e, nextBtn, prevBtn) {
        // console.log(e.keyCode);
        // console.log(nextBtn);
        // console.log(prevBtn);
        e.keyCode == 39 ? loadNextImg(e) : false;
        e.keyCode == 37 ? loadPrevImg(e) : false;
        e.keyCode == 27 ? normalizePic(e) : false;
    }

    function maximizePic(e) {
        e.preventDefault();
        // if (e.target.parentElement.classList)
        if (e.target.parentElement.classList.contains("js-gallery__item")) {
            return;
        }

        const imageOpened = e.target;
        const scrollTop = document.querySelector("html").scrollTop;
        const next = e.target.dataset.next;
        const prev = e.target.dataset.prev;

        const galleryTitle = imageOpened.dataset.title;
        const gallery = document.querySelector(
            `[data-gallery="${galleryTitle}"]`
        );
        // console.log(next);
        // console.log(prev);

        if (gallery !== null) {
            const nextBtn = document.querySelector(".js-next");
            const prevBtn = document.querySelector(".js-prev");

            nextBtn.addEventListener("click", loadNextImg);
            prevBtn.addEventListener("click", loadPrevImg);

            document.addEventListener("keydown", checkKey);
            document.addEventListener("keydown", checkKey);
        } else {
            document.querySelector(".js-next") !== null
                ? document.querySelector(".js-next").classList.add("is-hidden")
                : false;
            document.querySelector(".js-prev") !== null
                ? document.querySelector(".js-prev").classList.add("is-hidden")
                : false;
        }
        // console.log(gallery);

        document.querySelector(".js-gallery").classList.add("is-visible");
        document.querySelector("body").classList.add("u-overflow-hidden");
        document.querySelector("body").style.top = `-${scrollTop}px`;

        imageOpened.parentElement.classList.add("js-empty-pic");
        imageOpened.classList.add("c-gallery__picture");

        document.querySelector(".js-gallery__item").appendChild(imageOpened);

        document
            .querySelector(".js-close")
            .addEventListener("click", normalizePic);
    }

    function getPrevAndNext(image) {
        const activeImg = image;
        // console.log('activeImg: ', activeImg);

        // THIS IS THE PROBLEM I THINK
        // const activeImgUrl = new URL(image.src);

        const galleryTitle = image.dataset.title;
        const gallery = document.querySelector(
            `[data-gallery="${galleryTitle}"]`
        );
        // console.log('activeImgUrl: ', activeImgUrl);
        // console.log('galleryTitle: ', galleryTitle);
        // console.log('gallery: ', gallery);

        // If there is a gallery ('cause sometimes you have only one picture)
        if (gallery !== null) {
            // Maybe is this the issue with ie!
            // console.log(gallery.children);

            // gallery.children.forEach(item => {console.log(item)});

            for (var i = 0; i < gallery.children.length; ++i) {
                const item = gallery.children[i];

                // for (const item of gallery.children) {
                // https://stackoverflow.com/questions/44046806/ie-doesnt-loop-through-htmlcollection-properly

                // const theImg = gallery.children.item(item);
                const lastChild = gallery.children[gallery.children.length - 1];
                const firstChild = gallery.children[0];
                const imgUrl =
                    item.querySelector(".js-img-bg").style.backgroundImage;
                const itemUrl = imgUrl
                    .match(/(?:\(['"]?)(.*?)(?:['"]?\))/)[1]
                    .replace(/('|")/g, "");
                // console.log('lastChild: ', lastChild);
                // console.log('firstChild: ', firstChild);
                // console.log('itemUrl: ', itemUrl);

                // If both, activeImg and item==this.image from gallery are the same:
                // console.log(itemUrl, ' vs ', image.src);
                // console.log(image.src.includes(itemUrl));

                // if (itemUrl == activeImgUrl.pathname || itemUrl.includes(activeImgUrl.pathname)) {
                if (includes(image.src, itemUrl)) {
                    // if (image.src.includes(itemUrl)) {
                    // if (itemUrl == activeImgUrl.pathname || itemUrl.includes(activeImgUrl.pathname) || image.src.includes(itemUrl)) {
                    // console.log(itemUrl, activeImgUrl.pathname);
                    // give the same dataset-image number to the bigone
                    activeImg.dataset.image =
                        item.querySelector(".js-thumbnail").dataset.image;
                    let prevImgContainer;
                    let nextImgContainer;

                    if (item == firstChild) {
                        // console.log('firstChild', item == firstChild);
                        prevImgContainer = gallery.querySelector(
                            `[data-image="${
                                lastChild.querySelector(".js-thumbnail").dataset
                                    .image
                            }"]`
                        );
                    } else {
                        prevImgContainer = gallery.querySelector(
                            `[data-image="${
                                Number.parseInt(
                                    item.querySelector(".js-thumbnail").dataset
                                        .image
                                ) - 1
                            }"]`
                        );
                    }

                    if (item == lastChild) {
                        // console.log('lastChild', item == lastChild);
                        nextImgContainer = gallery.querySelector(
                            `[data-image="${
                                firstChild.querySelector(".js-thumbnail")
                                    .dataset.image
                            }"]`
                        );
                    } else {
                        nextImgContainer = gallery.querySelector(
                            `[data-image="${
                                Number.parseInt(
                                    item.querySelector(".js-thumbnail").dataset
                                        .image
                                ) + 1
                            }"]`
                        );
                    }

                    const next =
                        nextImgContainer.querySelector(".js-img-bg").style
                            .backgroundImage;
                    const prev =
                        prevImgContainer.querySelector(".js-img-bg").style
                            .backgroundImage;
                    // console.log('next', nextImgContainer, 'prev', prevImgContainer);
                    // console.log(activeImg);

                    const nextUrl = next
                        .match(/(?:\(['"]?)(.*?)(?:['"]?\))/)[1]
                        .replace(/('|")/g, "");
                    const prevUrl = prev
                        .match(/(?:\(['"]?)(.*?)(?:['"]?\))/)[1]
                        .replace(/('|")/g, "");

                    image.dataset.next = nextUrl;
                    image.dataset.prev = prevUrl;
                }
            }
        }
    }

    function changeImage(e) {
        e.preventDefault();
        e.stopPropagation();

        const thumbnailImgContainer = e.target.parentElement.parentElement;

        // If there is no previous element it will take the last one
        const prevImgContainer =
            thumbnailImgContainer.previousElementSibling ||
            thumbnailImgContainer.parentElement.lastElementChild;

        // If there is no next element it will take the first one
        const nextImgContainer =
            thumbnailImgContainer.nextElementSibling ||
            thumbnailImgContainer.parentElement.firstElementChild;

        const thumbnailBgImg = e.target.style.backgroundImage;
        const nextBgImg =
            nextImgContainer.querySelector(".js-img-bg").style.backgroundImage;
        const prevBgImg =
            prevImgContainer.querySelector(".js-img-bg").style.backgroundImage;

        const urlImage = thumbnailBgImg
            .match(/(?:\(['"]?)(.*?)(?:['"]?\))/)[1]
            .replace(/('|")/g, "");
        const nextUrlImage = nextBgImg
            .match(/(?:\(['"]?)(.*?)(?:['"]?\))/)[1]
            .replace(/('|")/g, "");
        const prevUrlImage = prevBgImg
            .match(/(?:\(['"]?)(.*?)(?:['"]?\))/)[1]
            .replace(/('|")/g, "");

        // console.log(e.target);

        const bigImage = document.querySelector(
            `.js-image[data-title="${e.target.dataset.title}"]`
        );
        bigImage.dataset.next = nextUrlImage;
        bigImage.dataset.prev = prevUrlImage;

        bigImage.dataset.image =
            nextImgContainer.querySelector(".js-img-bg").parentElement.dataset
                .image == 0
                ? Number.parseInt(
                      nextImgContainer.querySelector(".js-img-bg").parentElement
                          .parentElement.parentElement.children.length
                  ) - 1
                : Number.parseInt(
                      nextImgContainer.querySelector(".js-img-bg").parentElement
                          .dataset.image
                  ) - 1;

        bigImage.src = urlImage;
    }

    const bigPictures = document.querySelectorAll(".js-image");
    const thumbnails = document.querySelectorAll(".js-thumbnail .js-img-bg");
    var thumbnailsWordLink = document.querySelectorAll(".js-thumbnail-word");
    // const closeButtons = document.querySelectorAll('.js-close');

    // bigPictures.forEach(picture => picture.addEventListener('click', zoom));
    // closeButtons.forEach(picture => picture.addEventListener('click', close));
    if (thumbnails.length > 0) {
        bigPictures.forEach(getPrevAndNext);
    }

    bigPictures.forEach((picture) =>
        picture.addEventListener("click", maximizePic)
    );

    thumbnails.forEach((thumbnail) =>
        thumbnail.addEventListener("click", changeImage)
    );

    thumbnailsWordLink.forEach((thumbLink) =>
        thumbLink.addEventListener("click", (e) => {
            document
                .querySelector(
                    `.js-thumbnail[data-image="${e.target.dataset.image}"] .js-img-bg`
                )
                .click();
        })
    );
})();
