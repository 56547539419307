(function() {
    function toggleReadMore(e) {
        // e.stopPropagation();
        // e.preventDefault();
        // console.log('test');

        const container = e.target.parentElement;
        const target = container.parentElement;
        // console.log(target);
        if (target.style.maxHeight) {
            target.style.removeProperty('max-height');
            target.classList.remove('is-open');
        } else {
            target.style.maxHeight = '2510px';
            target.classList.add('is-open');
        }
        target.addEventListener('transitionend', toggleMessage, false);
        // target.removeEventListener('transitionend', toggleMessage);

        // target.addEventListener('transitionend', function(e){
        //     if (e.propertyName == 'max-height') {
        //         console.log(e.target);
        //         toggleMessage(e);
        //     }
        // });
        // target.removeEventListener('transitionend', toggleMessage);
    }

    function toggleMessage(e) {
        // e.stopPropagation();
        // e.preventDefault();
        // console.log(e.target.querySelector('.js-expand').dataset.more);

        const link = e.target.querySelector('.js-expand');

        // if (e.propertyName.indexOf('padding') >= 0 && e.target.parentElement.classList.contains('is-open')) {
        if (e.target.classList.contains('is-open')) {
            link.innerHTML = `${lblClose}`;
            link.classList.add('c-card__read-close');
            // console.log(e.target);
            // console.log('test 2', link);
        } else {
            // console.log(e.target);
            // console.log('test 2 else', link);
            // e.target.style.maxHeight = '400px';

            // e.target.classList.remove('is-open');

            link.innerHTML = `${lblMore}`;
            link.classList.remove('c-card__read-close');
            // console.log(e.target);


        }

        // const readMoreTriggers = document.querySelectorAll('.js-expand');
        // readMoreTriggers.forEach(trigger => trigger.addEventListener('click', toggleReadMore));
    }

    const readMoreTriggers = document.querySelectorAll('.js-expand');
    const lblMore = document.querySelector('.js-expand') !== null ? document.querySelector('.js-expand').dataset.more : '';
    const lblClose = document.querySelector('.js-expand') !== null ? document.querySelector('.js-expand').dataset.close : '';

    readMoreTriggers.forEach(trigger => trigger.addEventListener('click', toggleReadMore));
    // console.log('e.target');

})();
