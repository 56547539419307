(function() {
    function open(e) {
        e.preventDefault();
        // console.log(e.cancelable);

        // I think I can do this with .find() and const.
        let trigger;
        if (e.target.localName == 'a') {
            // console.log(`localname == a: ${e.target}`);
            trigger = e.target;
        } else {
            // console.log(`localname != a // parentelement: ${e.target.parentElement}`);
            trigger = e.target.parentElement;
        }
        // console.log(trigger);

        // console.log(trigger.getBoundingClientRect().left);
        const leftBound = trigger.getBoundingClientRect().left - document.querySelector('.o-container').getBoundingClientRect().left;
        const rightBound = trigger.getBoundingClientRect().right - document.querySelector('.o-container').getBoundingClientRect().right;
        const bottomBound = trigger.getBoundingClientRect().bottom;
        // console.log(trigger);

        if (window.innerWidth < 960) {
            // console.log(document.querySelectorAll(`.${trigger.dataset.target}`));
            document.querySelectorAll(`.${trigger.dataset.target}`).forEach(x => x.classList.toggle('is-hidden'));
        } else {
            if (trigger.parentElement.classList.contains('is-open-dropdown')) {
                // console.log('contains');
                document.querySelector('.is-open-dropdown').classList.remove('is-open-dropdown');
                document.querySelectorAll(`.${trigger.dataset.target}`).forEach(x => x.classList.add('is-hidden'));

            } else {
                if (document.querySelectorAll('.is-open-dropdown').length > 0) {
                    // console.log(document.querySelector('.is-open-dropdown a').dataset.target)

                    document.querySelectorAll(`.${document.querySelector('.is-open-dropdown a').dataset.target}`).forEach(x => x.classList.toggle('is-hidden'));
                    document.querySelector('.is-open-dropdown').classList.remove('is-open-dropdown');
                }

                trigger.parentElement.classList.toggle('is-open-dropdown');
                // console.log('test');
                document.querySelectorAll(`.${trigger.dataset.target}`).forEach(x => x.classList.toggle('is-hidden'));

                document.querySelectorAll(`.${trigger.dataset.target}`).forEach(x => {
                    if (!document.querySelector('body').classList.contains('c-home')) {
                        x.style.width = '100%';
                        x.style.paddingLeft = `${leftBound}px`;
                        x.style.top = `${bottomBound}px`;
                        // console.log(x.style);
                    }
                });
            }
        }


        // console.log(document.querySelectorAll(`.${trigger.dataset.target}` ));

        // } else {
        //     const menuTrigger = e.target;
        // }
        // console.log(document.querySelector(`.${trigger.dataset.target}`).classList);
    }

    const menuTrigger = document.querySelectorAll('.js-dropdown-trigger');
    menuTrigger.forEach(item => item.addEventListener('click', open));

    // console.log(document.querySelector('.is-selected'));
    if (document.querySelector('.is-selected') !== null) {
        if (document.querySelector('.is-selected').classList.contains('has-children')) {

            document.querySelectorAll('.is-selected').forEach(x => {
                if (x.offsetParent) {
                    if (x.classList.contains('has-children')) {
                        x.parentElement.classList.add('is-active-with-children');
                    }
                }
            });
            if (!document.querySelector('body').classList.contains('c-home')) {
                // x.style.width = `calc(100vw - ${leftBound}px - 2rem)`;
                const parentElement = document.querySelectorAll('.is-selected');

                let leftBound;
                let target;

                // console.log(parentElement);

                parentElement.forEach(x => {
                    if (x.classList.contains('has-children') && x.getBoundingClientRect().width > 0) {

                        // console.log(x);
                        leftBound = x.getBoundingClientRect().left;
                        target = x.querySelector('.c-nav-subnav');
                        // console.log(x.querySelectorAll('.c-nav-subnav'));
                    }
                    // if (!x.getBoundingClientRect().left == 0 && !x.classList.contains('is-hidden-until-bp4') {
                    //     console.log(x.getBoundingClientRect());
                    //     console.log());
                    //
                    // }
                })

                // const target = document.querySelector('.is-selected .c-nav-subnav');

                // console.log(parentElement);
                // console.log(leftBound);
                // console.log(target);

                // target.style.minWidth = `calc(100vw - ${leftBound}px - 2rem)`;
            }
            // menuTrigger.forEach(item => console.log(item));
        }
    }

})();
